<template>
  <form @submit.prevent="saveService" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Type<span class="text-red-400">*</span></label
      >
      <select class="bge-input bge-select rounded" v-model="service.type">
        <option value="Oil Change">Oil Change</option>
        <option value="Brake Fluid Change">Brake Fluid Change</option>
        <option value="Major Service">Major Service</option>
        <option value="Full Service">Full Service</option>
        <option value="Interim Service">Interim Service</option>
      </select>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Charge</label>
      <input
        v-model="service.charge"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Charge..."
      />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Date of Servicee<span class="text-red-400">*</span></label
      >
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="service.service_date"
        format="dd/MM/yyyy"
        use-utc="false"
        @selected="serviceDateSelected()"
      ></datepicker>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Recurring Period<span class="text-red-400">*</span></label
      >
      <select
        class="bge-input bge-select rounded"
        v-model="service.recurring_period"
        @change="onRepeatOptionChange($event)"
      >
        <option value="1">Month</option>
        <option value="3">3-Month</option>
        <option value="6">6-Month</option>
        <option value="12">Year</option>
        <option value="24">2-Year</option>
        <option value="36">3-Year</option>
        <option value="48">4-Year</option>
        <option value="60">5-Year</option>
      </select>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Expiry Date</label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="service.expiry_date"
        :open-date="openExpiryDate"
        :disabledDates="disabledExpiryDates"
        format="dd/MM/yyyy"
        use-utc="false"
        placeholder="Expiry Date..."
      ></datepicker>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Mileage</label>
      <input
        v-model="service.mileage"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Mileage..."
      />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Reminder Date<span class="text-red-400">*</span></label
      >
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="service.reminder_date"
        :open-date="openExpiryDate"
        :disabledDates="disabledExpiryDates"
        format="dd/MM/yyyy"
        use-utc="false"
      ></datepicker>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Notes</label>
      <textarea
        v-model="service.notes"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        rows="5"
        placeholder="Notes..."
      />
    </div>
    <div class="w-full">
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          type="button"
          @click="saveService"
          class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save Service</span>
        </button>
      </is-authorized>
    </div>
  </form>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
import futureDateByNMonth from "@/utils/futureDateByNMonth";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "AddEditService",
  components: {
    Datepicker,
    IsAuthorized,
  },
  props: {
    service: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      openExpiryDate: new Date(),
      disabledExpiryDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  methods: {
    saveService: function () {
      this.$emit("complete", this.service);
    },
    deleteService: function () {
      this.$emit("delete", this.service);
    },
    serviceDateSelected(e) {
      this.$nextTick(() => {
        this.openExpiryDate = new Date(this.service.service_date);
        this.disabledExpiryDates.to = new Date(this.service.service_date);

        if (this.service.recurring_period) {
          this.calculateDates(
            new Date(this.service.service_date),
            this.service.recurring_period,
          );
        }
      });
    },
    onRepeatOptionChange(event) {
      if (this.service.service_date) {
        this.calculateDates(
          new Date(this.service.service_date),
          event.target.value,
        );
      }
    },

    calculateDates(serviceDate, recurringPeriod) {
      const month = parseInt(recurringPeriod);

      // Calculate the Expiry Date
      const expiryDate = futureDateByNMonth(serviceDate, month);
      this.service.expiry_date = expiryDate;

      // Calculate the Reminder Date (subtracting 1 month from the Expiry Date)
      const reminderDate = futureDateByNMonth(serviceDate, month - 1);
      this.service.reminder_date = reminderDate;
    },
  },
};
</script>
